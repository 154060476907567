<template>
  <div class="product-page--create pa-4">
    <v-row no-gutters>
      <v-col cols="12">
        <redirect class="mb-5" title="Products" route="website.products" />
        <div class="page-header">
          <h2 class="page-title">Add Products</h2>
        </div>
      </v-col>
    </v-row>
    <GroupSubmit :isLoading="isLoading" :callbackCancel="onCancel" :callbackSubmit="onSubmit" />
    <v-row>
      <v-col cols="12">
        <Notification type="error" :messages="errors" v-if="errors.length > 0" />
      </v-col>
      <v-col cols="12" sm="8" class="d-flex flex-column">
        <div class="elevation-form pa-3">
          <InputDefault
            :min="0"
            :max="255"
            :model="['attributes', 'title']"
            fieldName="Title"
            label="Title"
            :isValidate="true"
          />
          <!-- <ContentInput :model="`bodyHtml`" title="Description" subtitle="Maximum 100.000 characters" /> -->
          <div class="form-component">
            <label class="d-flex" style="font-size: 14px;">Description</label>
            <ckeditor :editor="editor" v-model="attributes.bodyHtml" :config="editorConfig"></ckeditor>
          </div>
        </div>
        <div class="elevation-form mt-4 pa-3">
          <Images />
        </div>
        <div class="elevation-form mt-4 pa-3">
          <Pricing />
        </div>
        <div class="elevation-form mt-4 pa-3">
          <Inventory />
        </div>
        <div class="elevation-form pa-3 mt-4">
          <Shipping />
        </div>
        <div class="elevation-form pa-3 mt-4">
          <Variants />
        </div>
        <div class="mt-4">
          <v-col cols="12" sm="12" xs="12" class="pa-3 elevation-form">
            <template>
              <v-row justify="center">
                <v-expansion-panels accordion>
                  <v-expansion-panel>
                    <v-expansion-panel-header disable-icon-rotate>
                      <div>
                        <h3>Search engine listing preview</h3>
                        <p class="mt-5">
                          Add a title and description to see how this collection might appear in a search engine listing
                        </p>
                      </div>
                      <template v-slot:actions>
                        <span class="btn-link">Edit website SEO</span>
                      </template>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <template>
                        <div class="mt-3">
                          <label for="">Meta title</label>
                          <v-text-field
                            v-model="attributes.metafieldsGlobalTitleTag"
                            class=""
                            counter="70"
                            maxlength="70"
                            label=""
                          ></v-text-field>
                        </div>

                        <div class="mt-3">
                          <label for="">Meta description</label>
                          <v-textarea
                            v-model="attributes.metafieldsGlobalDescriptionTag"
                            class=""
                            maxlength="320"
                            counter="320"
                            label=""
                          ></v-textarea>
                        </div>

                        <div class="mt-3">
                          <label for="">URL and handle</label>
                          <v-text-field
                            v-model="attributes.metafieldsGlobalUrl"
                            class=""
                            label=""
                            :prefix="storeURL + '/products/'"
                          ></v-text-field>
                          <v-checkbox
                            v-model="attributes.metafieldsGlobalUrlRedirect"
                            :label="`Create a URL redirect to ${attributes.metafieldsGlobalUrl}`"
                          ></v-checkbox>
                        </div>
                      </template>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-row>
            </template>
          </v-col>
        </div>
      </v-col>
      <v-col cols="12" sm="4" class="d-flex flex-column">
        <!-- <div class="elevation-form pa-3">
          <ProductAvailability />
        </div> -->
        <div class="elevation-form pa-3">
          <Organization />
        </div>
        <div v-for="(option, index) in getOptionTemplates" :key="index">
          <div class="elevation-form pa-3" v-if="option.type == 'options' && Array.isArray(option.values)">
            <Options
              :name="option.name"
              :values="option.values"
              :onSelectedOptions="onSelectedOptions"
              :removeOption="removeOption"
            />
          </div>
        </div>
      </v-col>
      <v-col cols="12">
        <v-divider></v-divider>
      </v-col>
      <v-col cols="12" class="d-flex">
        <v-spacer></v-spacer>
        <v-btn outlined class="mr-3" @click="onCancel">Discard</v-btn>
        <v-btn :loading="isLoading" :disabled="isLoading" color="primary" @click="onSubmit">Create</v-btn>
      </v-col>
    </v-row>
    <BeforeLeavePage />
  </div>
</template>

<script>
import utilities from '@/helpers/utilities';
import { required, minLength, maxLength, between, email } from 'vuelidate/lib/validators';
// import resourceBeforeLeavePage from '@/mixins/before-change-router';
import { productApi } from '@/apis/product';

// import ContentInput from '@/views/website/components/Editor';
import Images from '@/views/website/products/components/Images';
// import ProductAvailability from '@/views/website/products/components/ProductAvailability';
import Organization from '@/views/website/products/components/Organization';
import Pricing from '@/views/website/products/components/Pricing';
import Inventory from '@/views/website/products/components/Inventory';
import Shipping from '@/views/website/products/components/Shipping';
import Variants from '@/views/website/products/components/Variants';
import { FETCH_PRODUCT_OPTION_TEMPLATES } from '@/store/actions.type';
import { mapGetters } from 'vuex';
import Options from './components/Options.vue';
import event from '@/plugins/event-bus';
// @ts-ignore
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { ckeditorConfig } from '@/helpers/editor';

export default {
  // mixins: [resourceBeforeLeavePage],
  components: {
    // ContentInput,
    Images,
    // ProductAvailability,
    Organization,
    Pricing,
    Inventory,
    Shipping,
    Variants,
    Options,
  },
  async created() {
    await this.$store.dispatch(FETCH_PRODUCT_OPTION_TEMPLATES);
  },
  data() {
    let defaultForm = {
      title: '',
      bodyHtml: '',
      published: true,
      tags: [],
      vendor: '',
      images: [],
      productType: '',
      group: null,
      collections: '',
      metafieldsGlobalDescriptionTag: '',
      metafieldsGlobalTitleTag: '',
      metafieldsGlobalUrl: '',
      metafieldsGlobalUrlRedirect: false,
      gtin: '',
      // collections: [
      //   {
      //     _id: '6002b02bdddb2b3bbc85f42d',
      //   },
      // ],
      deleteImageIds: [], // chua biet giao dien the nao
      variants: [
        {
          active: true,
          title: '', //option1/option2/option3
          option1: '',
          option2: '',
          option3: '',
          imageId: '',
          barCode: '',
          compareAtPrice: '',
          inventoryManagement: '', // [no, hiweb]Nhập, mặc định là no, nếu no không cập nhật quantity, nếu  hiweb thì cập nhật sau khi có order
          costPerItem: null, // cost item
          inventoryPolicy: 'deny', //continue, deny
          inventoryQuantity: null, // Số sản phẩm trong store, 0 = Soldout
          requiresShipping: true,
          weight: 0,
          weightUnit: 'lb',
          taxable: true,
          isDefault: true,
          price: null,
          sku: '',
          fulfillmentService: 'hiweb',
          baseCost: null,
        },
      ],
    };
    let variantDefaultForm = {
      // se an theo 1 trong 2 thang, uu tien variants
      title: 'Default Title',
      active: true,
      barCode: '',
      compareAtPrice: '',
      inventoryManagement: '', // [no, hiweb]Nhập, mặc định là no, nếu no không cập nhật quantity, nếu  hiweb thì cập nhật sau khi có order
      costPerItem: null, // cost item
      inventoryPolicy: 'deny', //continue, deny
      inventoryQuantity: null, // Số sản phẩm trong store, 0 = Soldout
      requiresShipping: true,
      weight: 0,
      weightUnit: 'lb',
      taxable: true,
      isDefault: true,
      price: null,
      sku: '',
      fulfillmentService: 'hiweb',
      baseCost: null,
    };
    return {
      isLoading: false,
      page: {},
      showHeader: false,
      searchString: 'null hihi',
      attributes: Object.assign({}, defaultForm),
      variantDefault: Object.assign({}, variantDefaultForm),
      defaultForm,
      variantDefaultForm,
      listVariants: [],
      // Product options
      options: [], // need
      errors: [],
      detailOptions: [],
      editor: Editor,
      editorConfig: ckeditorConfig,
    };
  },
  methods: {
    onCancel() {
      this.$v.$reset();
      this.attributes = Object.assign({}, this.defaultForm);
      this.$forceUpdate();
      this.$router.push({ name: 'website.products' });
    },
    async onSubmit() {
      this.$v.$touch();
      utilities.scrollToTop();
      if (!this.$v.$invalid) {
        try {
          this.isLoading = true;
          this.errors = [];
          let isMissingPrice = false;
          let convertData = {
            options: this.options,
            ...this.attributes,
            variantDefault: this.variantDefault,
            variants:
              this.listVariants && this.listVariants.length > 0
                ? this.listVariants.filter(item => {
                    return item.active === true;
                  })
                : null,
            collections:
              this.attributes.collections && this.attributes.collections.length > 0
                ? this.attributes.collections.map(itemem => {
                    return { _id: itemem };
                  })
                : null,
            detailOptions: this.detailOptions,
          };
          isMissingPrice = !Number(convertData.variantDefault.price);
          if (convertData?.variants?.length) {
            isMissingPrice = convertData.variants.some(item => !item.price);
          }
          if (isMissingPrice) {
            this.$store.commit('setMessages', {
              messages: 'You have to set price for this product!',
              type: 'error',
            });
            this.isLoading = false;
          } else {
            let res = await productApi.create(convertData);
            setTimeout(() => {
              if (res) {
                this.$router.push({ name: 'website.products.update', params: { id: res.data.product._id } });
              }
              this.isLoading = false;
            }, 10);
            event.$emit('getStepStatus', message => {
              this.getStep();
            });
          }
        } catch (error) {
          this.isLoading = false;
          console.log('🚀 ~ file: Create.vue:297 ~ onSubmit ~ error:', error);
        } finally {
          this.isLoading = false;
        }
      } else {
        this.errors = ['Validate error'];
      }
    },
    onSelectedOptions(name, values) {
      for (const value of values) {
        if (!this.attributes.tags.includes(value)) {
          this.attributes.tags.unshift(value);
        }
      }
      let optionIndex = this.detailOptions.find(option => option.name == name);
      if (optionIndex) {
        this.detailOptions[optionIndex].values = values;
      } else {
        this.detailOptions.push({ name, values });
      }
    },
    removeOption(name, value) {
      if (this.attributes.tags.includes(value)) {
        const index = this.attributes.tags.indexOf(value);
        if (index >= 0) this.attributes.tags.splice(index, 1);
      }

      this.detailOptions.map(option => {
        if (option.name == name) {
          const index = option.values.indexOf(value);
          if (index >= 0) option.values.splice(index, 1);
        }
        return option;
      });
    },
    defaultOption(tags) {
      for (const tag of tags) {
        // Duyệt qua tags
        const template = this.getOptionTemplates?.find(template => template?.values?.includes(tag)); // Nếu có template nào matching thì xử lý
        if (!template) continue;
        let optionIndex = this.detailOptions?.findIndex(option => option && option?.name == template?.name); // Lấy ra option matching
        if (optionIndex != -1) {
          // Nếu đã có thì thêm vào mảng sẵn có
          this.detailOptions[optionIndex]?.values?.push(tag);
        } else {
          // không thì tạo cái mới
          this.detailOptions.push({ name: template?.name, values: [tag] });
        }
      }
    },
  },
  watch: {
    attributes: {
      handler: function(val, oldVal) {
        this.showHeader = !utilities.comparisonTwoObject(this.defaultForm, this.attributes);
        // if (this.showHeader) {
        //   this.$v.$reset();
        // }
      },
      deep: true,
    },
    'attributes.tags': function(val, oldVal) {
      this.defaultOption(val);
    },
  },
  computed: {
    ...mapGetters(['getOptionTemplates']),
    storeURL() {
      return 'https://' + this.$store.state.auth.accountInfo.rDomain;
    },
  },
  validations: {
    attributes: {
      title: {
        required,
        minLength: minLength(0),
        maxLength: maxLength(255),
      },
    },
  },
};
</script>
<style lang="scss">
.product-page--create {
  .btn-link {
    color: $main-color;
    &:hover {
      text-decoration: none;
    }
  }
  .v-expansion-panel:before {
    box-shadow: none;
  }
  .v-expansion-panel-header__icon {
    position: absolute;
    right: 20px;
    top: 20px;
  }
}
</style>
